@import "../../../base/fn";

.weui-label{
  display:block;
  width:@weuiCellLabelWidth;
  .text_wrap();
}
.weui-input {
    width: 100%;
    border: 0;
    outline: 0;
    -webkit-appearance: none;
    background-color: transparent;
    font-size: inherit;
    color: inherit;
    height: unit(@weuiCellLineHeight, em);
    line-height: @weuiCellLineHeight;

    // hides the spin-button
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
}
.weui-textarea {
    display: block;
    border: 0;
    resize: none;
    width: 100%;
    color: inherit;
    font-size: 1em;
    line-height: inherit;
    outline: 0;
}

.weui-textarea-counter{
    color: @weuiTextColorTips;
    text-align: right;
    .weui-cell_warn &{
        color: @weuiTextColorWarn;
    }
}

.weui-toptips {
    display:none;
    position: fixed;
    transform: translateZ(0);
    top: 0;
    left: 0;
    right: 0;
    padding:5px;
    font-size:14px;
    text-align: center;
    color: #FFF;
    z-index: 5000;
    .text_wrap();
}
.weui-toptips_warn {
    background-color: @weuiTextColorWarn;
}
.weui-cells_form {
    .weui-cell__ft{
        font-size:0;
    }
    .weui-icon-warn{
        display:none;
    }
    input, textarea, label[for]{
        .setTapColor();
    }
}
.weui-cell_warn{
    color:@weuiTextColorWarn;
    .weui-icon-warn{display:inline-block;}
}
